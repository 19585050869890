import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import Router from 'routes'
import * as serviceWorker from './serviceWorker'

// import createApp from '@shopify/app-bridge'
// import { Redirect } from '@shopify/app-bridge/actions'

// const apiKey = 'ccaa2b9a4f868c32be6f33840c47c68b'
// const redirectUri = 'https://b211-124-35-179-173.jp.ngrok.io'
// const permissionUrl = `https://${host}/admin/oauth/authorize?client_id=${apiKey}&scope=read_products,read_content&redirect_uri=${redirectUri}`

// const app = createApp({
//   apiKey: apiKey,
//   host: host
// })

// Redirect.create(app).dispatch(Redirect.Action.REMOTE, permissionUrl)
ReactDOM.render(<Router />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

import React, { lazy, Suspense } from 'react'
import { Helmet } from 'react-helmet'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { CustomFetchProvider } from 'utils/fetch'

const Preview = lazy(() => import('./preview'))
const Shopify = lazy(() => import('./shopify'))
const Root = lazy(() => import('./root'))

const AppRouter: React.FC = () => {
  return (
    <Router>
      <Helmet>
        <title>DATA CAST</title>
      </Helmet>
      <Suspense fallback={null}>
        <CustomFetchProvider>
          <Switch>
            <Route path="/preview">
              <Preview />
            </Route>
            <Route path="/shopify">
              <Shopify />
            </Route>
            <Route path="/">
              <Root />
            </Route>
          </Switch>
        </CustomFetchProvider>
      </Suspense>
    </Router>
  )
}

export default AppRouter

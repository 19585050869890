type Env =
  | 'development'
  | 'test'
  | 'sandbox'
  | 'sandboxvpc'
  | 'staging'
  | 'production'

type Config = {
  api: string
  root: string
  shopifyApiKey: string
}

export const env: Env =
  (process.env.REACT_APP_ENV as Env) ||
  (process.env.NODE_ENV as Env) ||
  'development'

const configMap: { [E in Env]: Config } = {
  development: {
    api: 'http://api.data-cast.test:3001',
    root: 'http://front.data-cast.test:3000',
    shopifyApiKey: process.env.REACT_APP_SHOPIFY_API_KEY || ''
  },
  test: {
    api: 'http://api.data-cast.test:3001',
    root: 'http://front.data-cast.test:3000',
    shopifyApiKey: ''
  },
  sandboxvpc: {
    api: 'https://sandbox-ns-auto-api.sandbox-data-cast.net',
    root: 'https://sandbox-data-cast.net',
    shopifyApiKey: '92864e2f0f1691a4b1e5861bda31c366'
  },
  sandbox: {
    api: 'https://sandbox3-ns-auto-api.dev-data-cast.net',
    root: 'https://sandbox.dev-data-cast.net',
    shopifyApiKey: '561b50a83a8fcb863f3db4d1151c5861'
  },
  staging: {
    api: 'https://staging3-ns-auto-api.dev-data-cast.net',
    root: 'https://dev-data-cast.net',
    shopifyApiKey: ''
  },
  production: {
    api: 'https://production3-ns-auto-api.data-cast.jp',
    root: 'https://data-cast.jp',
    shopifyApiKey: ''
  }
}

export default configMap[env]

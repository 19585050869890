import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'

export function useIsMounted() {
  const isMounted = useRef(true)
  useEffect(
    () => () => {
      isMounted.current = false
    },
    []
  )
  return isMounted
}

type Dispatch<A> = (value: A) => void
type SetStateAction<S> = S | ((prevState: S) => S)

export function useSafeState<S>(
  initialState: S | (() => S)
): [S, Dispatch<SetStateAction<S>>] {
  const [state, setState] = useState(initialState)
  const isMounted = useIsMounted()
  return [
    state,
    useCallback(
      (state: SetStateAction<S>) => {
        if (isMounted.current) {
          setState(state)
        }
      },
      [isMounted]
    )
  ]
}

export function useHtmlClass(className: string) {
  useEffect(() => {
    document.documentElement.classList.add(className)
    return () => document.documentElement.classList.remove(className)
  }, [className])
}

export function useErrorBoundary<T = any>() {
  const [, setState] = useState()
  return useCallback(
    (fn: () => Promise<T>) => {
      ;(async () => {
        fn().catch(error => {
          setState(() => {
            throw error
          })
        })
      })()
    },
    [setState]
  )
}

export function usePrevious<T>(value: T) {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export function useDidUpdateEffect(fn: () => void, inputs: any[]) {
  const didMountRef = useRef(false)
  useEffect(() => {
    if (didMountRef.current) fn()
    else didMountRef.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, inputs)
}

export function useAbortableCallback<T extends Function>(
  create: (signal: AbortSignal) => T,
  deps: React.DependencyList
): T {
  const [fn, setFn] = useState(() => create(new AbortController().signal))
  useEffect(() => {
    const abortController = new AbortController()
    setFn(() => create(abortController.signal))
    return () => abortController.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
  return fn
}

export function createSharedContext<T>(
  defaultValue: T
): [React.FC, () => [T, (value: T) => void]] {
  const Context = createContext<[T, (value: T) => void]>([
    defaultValue,
    () => {}
  ])

  const Provider: React.FC = ({ children }) => {
    const [value, setValue] = useState<T>(() => defaultValue)
    return (
      <Context.Provider value={[value, setValue]}>{children}</Context.Provider>
    )
  }

  const useShared = () => useContext(Context)

  return [Provider, useShared]
}

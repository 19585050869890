import { toast } from 'react-toastify'

export class HttpError extends Error {
  constructor(
    public response: Response,
    public statusCode: number,
    message = ''
  ) {
    super(message)
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

/**
 * @summary レスポンステキストからエラー表示する
 * @param {string} responseText レスポンステキスト
 * @param {string} default_msg エラー未定義時に表示するエラー文言
 */
export function toastErrorMsg(
  responseText: string,
  default_msg: string = '失敗しました'
) {
  const msgs = errObject2Msg(JSON.parse(responseText))

  if (msgs.length <= 0) {
    toast.error(default_msg)
  } else {
    toast.error(msgs.join('\n'))
  }
}

/**
 * @summary レスポンステキストオブジェクトから定義済みエラーを取得
 * @param {Object} obj オブジェクト化したレスポンステキスト
 * @returns {Array} エラーメッセージ配列
 */
function errObject2Msg(obj: object) {
  var msg: string[] = []

  if (!obj) return msg
  Object.entries(obj).forEach(([k, v]) => {
    if (typeof k !== 'string') return
    if (Array.isArray(v)) {
      Object.entries(v).forEach(([kk, vv]) => {
        if (vv && typeof vv === 'string') {
          const _msg = getDefinedErrorMessage(k, vv)
          if (_msg) msg.push(_msg)
        }
      })
    } else if (typeof v === 'string') {
      const _msg = getDefinedErrorMessage(k, v)
      if (_msg) msg.push(_msg)
    }
  })

  return msg
}

type CODE_MSG = {
  field: string
  code: string
  msg: string
}
/**
 * @summary フィールド・コード毎エラーメッセージ定義
 */
const ERR_CODE_MSGS: CODE_MSG[] = [
  {
    field: 'message',
    code: 'CANNOT_DELETE',
    msg: 'このアカウントは削除出来ません'
  },
  {
    field: 'spotDeliverAt',
    code: 'OVER_RESERVE_TIME_60',
    msg: '配信日時は60分後以降の日時を指定してください'
  },
  {
    field: 'spotDeliverAt',
    code: 'AFTER_DM_ACCEPTABLE_HOURS',
    msg: '本日以降の日付を指定してください（本日分の受付は午後4時まで）'
  },
  {
    field: 'activate',
    code: 'USING_DELETED_PRODUCT',
    msg:
      '配信対象絞り込み条件に使用している商品もしくはカテゴリが削除されています'
  },
  {
    field: 'segment',
    code: 'USING_DELETED_PRODUCT',
    msg:
      '配信対象絞り込み条件に使用している商品もしくはカテゴリが削除されています'
  },
  {
    field: 'deactivate',
    code: 'NOT_STOPPABLE',
    msg: '配信連携中のためキャンペーン停止は出来ません'
  },
  {
    field: 'campaignDestroy',
    code: 'NOT_DELETABLE',
    msg: '配信連携中のためキャンペーン削除は出来ません'
  }
]

/**
 * @summary フィールド項目、エラーコードに定義されているエラーメッセージ取得
 * @param {string} field フィールド項目名
 * @param {string} errCode エラーコード
 * @returns {string} ERR_CODE_MSGSに定義されているエラーメッセージ
 */
function getDefinedErrorMessage(field: string, errCode: string): string {
  var msg = ''

  Object.entries(ERR_CODE_MSGS).forEach(([k, _msg]) => {
    if (_msg.field === field && _msg.code === errCode) msg = _msg.msg
  })

  return msg
}

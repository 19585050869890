import deepmerge from 'deepmerge'
import isPlainObject from 'is-plain-object'

export function isEmpty(obj?: Object): boolean {
  if (!obj) return true
  return !Object.keys(obj).length
}

export function enumValues<E>(Enum: any): E[] {
  return Object.keys(Enum)
    .filter(k => typeof Enum[k] === 'number')
    .map(k => Enum[k])
}

export function traverse(obj: any, fn: (o: any) => void) {
  if (Array.isArray(obj)) {
    obj.forEach(item => traverse(item, fn))
  } else if (typeof obj === 'object' && obj) {
    fn(obj)
    Object.values(obj).forEach(item => traverse(item, fn))
  }
}

export function merge<T>(x: Partial<T>, y: Partial<T>): T {
  return deepmerge(x, y, {
    isMergeableObject: isPlainObject
  })
}

export function compact<T>(obj: T): Partial<T> {
  const result: any = {}
  Object.entries(obj).forEach(([key, value]) => {
    if (value || value === false) {
      result[key] = value
    }
  })
  return result
}

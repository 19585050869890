import config from 'env-config'
import { History } from 'history'
import querystring from 'querystring'
import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import Cookies from 'universal-cookie'
import { HttpError } from 'utils/error'
import { isEmpty, merge } from 'utils/object'
import { createSharedContext } from './hook'

const CSRF_COOKIE_KEY = 'csrftoken'
const cookie = new Cookies()

type Fetch = typeof fetch
type FetchOptions = RequestInit & {
  data?: any
  fetch?: Fetch
}

const [CustomFetchProvider, useCustomFetch] = createSharedContext(fetch)
export { CustomFetchProvider, useCustomFetch }

async function _request(url: string, options: FetchOptions) {
  if (!/^https?:/.test(url)) {
    url = `${config.api}${url}`
  }

  if (!isEmpty(options.data)) {
    if (options.method === 'GET') {
      url = url + '?' + querystring.stringify(options.data)
    } else {
      options.body = JSON.stringify(options.data)
    }
  }
  delete options.data

  const _fetch = options.fetch || fetch
  delete options.fetch

  const defaultInit: RequestInit = {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': cookie.get(CSRF_COOKIE_KEY) || undefined
    }
  }
  const init: RequestInit = merge(defaultInit, options)

  const res = await _fetch(url, init)
  if (!res.ok) throw new HttpError(res, res.status, res.statusText)
  return res
}

async function request<Response = any>(
  url: string,
  options: FetchOptions
): Promise<Response> {
  const res = await _request(url, options)
  return res.json()
}

async function requestWithoutResponse(
  url: string,
  options: FetchOptions
): Promise<void> {
  await _request(url, options)
}

export type FetchMethods = {
  get: <Response>(url: string, options?: FetchOptions) => Promise<Response>
  post: <Response>(url: string, options?: FetchOptions) => Promise<Response>
  put: <Response>(url: string, options?: FetchOptions) => Promise<Response>
  patch: <Response>(url: string, options?: FetchOptions) => Promise<Response>
  del: (url: string, options?: FetchOptions) => Promise<void>
}

export function createMethod(method: string, fetch?: Fetch) {
  return function<Response = any>(url: string, options: FetchOptions = {}) {
    return request<Response>(url, {
      method,
      fetch,
      ...options
    })
  }
}

function createMethodWithoutResponse(method: string, fetch?: Fetch) {
  return function(url: string, options: FetchOptions = {}) {
    return requestWithoutResponse(url, {
      method,
      fetch,
      ...options
    })
  }
}

export function useFetch(): FetchMethods {
  const [fetch] = useCustomFetch()
  return useMemo(
    () => ({
      get: createMethod('GET', fetch),
      post: createMethod('POST', fetch),
      put: createMethod('PUT', fetch),
      patch: createMethod('PATCH', fetch),
      del: createMethodWithoutResponse('DELETE', fetch)
    }),
    [fetch]
  )
}

function withRedirect(fn: any, history: History) {
  return async <Response = any>(
    url: string,
    options?: FetchOptions
  ): Promise<Response> => {
    try {
      return await fn(url, options)
    } catch (error) {
      if (error instanceof HttpError && error.statusCode === 403) {
        console.log('error in withRedirect')
        history.push(
          window.location.pathname.startsWith('/shopify')
            ? '/shopify/login'
            : '/login'
        )
        toast.error('認証に失敗しました')
      }
      throw error
    }
  }
}

function withRedirectWithoutResponse(fn: any, history: History) {
  return async (url: string, options?: FetchOptions) => {
    await withRedirect(fn, history)(url, options)
  }
}

export function useFetchWithRedirect(): FetchMethods {
  const history = useHistory()
  const { get, post, put, patch, del } = useFetch()

  return useMemo(
    () => ({
      get: withRedirect(get, history),
      post: withRedirect(post, history),
      put: withRedirect(put, history),
      patch: withRedirect(patch, history),
      del: withRedirectWithoutResponse(del, history)
    }),
    [del, get, history, patch, post, put]
  )
}
